import { Machine } from 'xstate'

export const triggerMachine = Machine(
  {
    id: 'trigger',
    initial: 'idle',
    context: {
      activeMuscle: 0,
      routines: []
    },
    states: {
      idle: {
        on: {
          START: {
            target: 'training'
          }
        }
      },
      training: {
        initial: 'muscleStart',
        states: {
          muscleStart: {
            on: {
              START: 'counting'
            }
          },
          counting: {
            after: [
              {
                delay: context => {
                  const muscle = context.muscles[context.activeMuscle].data
                  return muscle.Time_Per_Unit * 1000 * muscle.Units
                },
                target: 'muscleStart',
                actions: ['incrementActiveMuscle']
              }
            ],
            on: {
              PAUZE: 'pauzed'
            }
          },
          pauzed: {
            on: {
              RESUME: 'counting'
            }
          }
        }
      }
    }
  },
  {
    actions: {
      incrementActiveMuscle: context => {
        console.log('increment boom')

        context.activeMuscle += 1
      }
    }
  }
)
