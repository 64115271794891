import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useMachine } from '@xstate/react'
import { triggerMachine } from '../machines/triggy.js'
import { assign } from 'xstate/lib/actions'

const IndexPage = ({ data }) => {
  const { muscles, routines } = data
  const [current, send] = useMachine(triggerMachine, {
    context: {
      routines: routines.nodes,
      activeRoutine: routines.nodes[0].data.id,
      muscles: muscles.nodes
    }
  })

  useEffect(() => {
    console.log(current.context)
  })

  return (
    <Layout>
      <SEO keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]} title="Home" />
      {current.matches('idle') && (
        <>
          <h1>Welccome to the triggert!</h1>
          <select
            name="routine"
            id="routine"
            value={current.context.activeRoutine}
            onChange={event => {
              assign({ context: { activeRoutine: event.target.value } })
            }}
          >
            {routines.nodes.map(node => {
              const routine = node.data
              return (
                <option key={routine.id} value={routine.id}>
                  {routine.Name}
                </option>
              )
            })}
          </select>
          <button onClick={() => send('START')}>Start This one</button>
        </>
      )}
      {/* <h1 className="text-5xl">{current.context.routineName()}</h1> */}
      {current.matches('training') &&
        muscles.nodes.map((node, index) => {
          if (index !== current.context.activeMuscle) return

          const muscle = node.data
          return (
            <section key={muscle.id} className="mb-8 overflow-hidden bg-white rounded-lg shadow">
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">{muscle.Name}</div>
              <div className="px-4 py-5 sm:p-6">{muscle.Notes}</div>
              <div className="px-4 py-5 sm:p-6">
                {muscle.Attachments &&
                  muscle.Attachments.map(att => {
                    return <img key={att} src={att.thumbnails.large.url} alt="" />
                  })}
              </div>
            </section>
          )
        })}
      {current.matches('training.muscleStart') && (
        <button onClick={() => send('START')}>Start This one</button>
      )}
      {current.matches('training.counting') && <h3>Counting</h3>}
    </Layout>
  )
}

// "Name": "Masseter (Cheekbone)",
//             "Tool": [
//               "recqYFvUxKz2v5nJE"
//             ],
//             "Notes": "Fingers, Jaw open to stretch",
//             "Attachments": [
//               {
//                 "thumbnails": {
//                   "large": {
//                     "url": "https://dl.airtable.com/.attachmentThumbnails/a6860a41721bb8a77c81cda8f980fe52/07604a23"
//                   }
//                 }
//               }
//             ],
//             "Time_Per_Unit": 15,
//             "Units": 2
export const query = graphql`
  {
    muscles: allAirtable(
      sort: { fields: data___Position }
      filter: { table: { eq: "tblRVoizQ5gZH7vQY" } }
    ) {
      nodes {
        data {
          Name
          Tool
          Notes
          Attachments {
            thumbnails {
              large {
                url
              }
            }
          }
          Time_Per_Unit
          Units
        }
      }
    }
    routines: allAirtable(filter: { table: { eq: "tblUVt5qkTUJSRnoz" } }) {
      nodes {
        id
        data {
          Name
          Notes
        }
      }
    }
  }
`

export default IndexPage
